body{
  margin: 0;
  padding: 0;
  font-family: montserrat;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw0aXpsog.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw9aXpsog.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw2aXpsog.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aXpsog.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Typography-start */
h1, h2, h3, h4, h5, h6{
  font-family: montserrat !important;
  font-weight: 700 !important;
  color: #282828;
  margin: 0;
}
h1{
  font-size: 44px;
}
h1 span{
  color: #0A6CC7;
}
/* Typography-end */
img{
  display: block;
  width: 100%;
}
.btn{
  border-radius: 30px !important;
}
/* navbar-start */
.navbar-brand{
  max-width: 230px;
}
.navbar-brand img{
  width: 100%;
  display: block;
}
.navbar-brand a {
  color: #fff;
  text-decoration: none;
}
.navbar-brand a h3 {
  margin-bottom: 0;
  color: #fff;
}
.navbar-expand-lg .navbar-nav a{
  padding: 10px 15px;
  margin: 0 3px;
  text-decoration: none;
  color: #fff;
}
.navbar-expand-lg .navbar-nav a.active,
.navbar-expand-lg .navbar-nav a:focus,
.navbar-expand-lg .navbar-nav a:hover{
  color:#F9A947;
  text-decoration: underline;
}
.navbar-expand-lg .navbar-nav a.btn-warning,
.navbar-expand-lg .navbar-nav a.btn-warning:focus,
.navbar-expand-lg .navbar-nav a.btn-warning:hover,
.navbar-expand-lg .navbar-nav a.btn-warning:visited{
  background-color: #F9A947 !important;
  color: #ffffff;
  text-decoration: none;
}
.navbgOnScroll{
  background-color: #043056;
  transition: ease-in ;
}
.navbar-toggler{
  background-color: white;
}
.navbar-toggler-icon{
  color: #fff !important;
}

@media (max-width: 990px) {
  .navbar-collapse{
    background-color: #043056;
  }
}
.navbar-nav .dropdown-menu{
  background-color: #0A6CC7;
  padding: 0;
  overflow: hidden;
  border-radius: 0 !important;
}
.navbar-nav .dropdown-menu a{
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid var(--bs-dropdown-divider-bg);
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: #F9A947;
  color: #ffffff;
}

/* navbar-End */
.App section:nth-child(odd) {
  background: #ffffff;
}

.App section:nth-child(even) {
  background: #F8F8F8;
}
/* main-heading-start */
.main-heading{
  margin-bottom: 1.85rem;
}
.main-heading .bottom-border{
  text-align: center;
  display: flex;
  justify-content: center;
}
.main-heading-left .bottom-border{
  text-align: left;
  display: flex;
  justify-content: left;
}
.main-heading .bottom-border .blue, .main-heading .bottom-border .yellow{
  border-radius: 9px;
  height: 5px;
}
.main-heading .bottom-border .blue{
 background-color: #0A6CC7;
 margin-right: 5px;
 width: 100px;
}
.main-heading .bottom-border .yellow{
  background-color: #F9A947;
  width: 20px;
 }
/* heading white */
.main-heading-white h1, .main-heading-white h1 span{
  color: #ffffff;
}
 .main-heading-white .bottom-border .blue{
 background-color: #ffffff;
 margin-right: 5px;
 width: 100px;
}

 /* main-heading-end */

 /* Home Header Start*/
 .homeHeader{
  background: url(./img/mainheader-bg.jpg);
  background-size: cover;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
 }
 .homeHeader .yellowtag h4{
  color: #ffffff;
  background-color: #F9A947;
  padding: 0.65rem 1.25rem;
  border-radius: 30px;
 }
 .typetext h1{
  text-transform: uppercase;
  color: #fff;
  font-size: 80px;
  font-weight: 800 !important;
}
 .typetext h1 span {
  color: #F9A947;
  font-weight: 200;
}
.homeHeader p{
  color: #ffffff;
}
.homeHeader p strong{
  color: #F9A947;
}
 /* Home Header End*/
/* homeMainFeatures */
.homeMainFeatures{
  position: relative;
  top:-60px;
  z-index: 1;
}
.homeMainFeatures .card {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  border: 0;
}
.homeMainFeatures .card:hover, .homeMainFeatures .card:hover .card-body .icon{
  background-color: #F9A947;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  border: 0;
  color: #fff !important;
  cursor: default;
  /* transition: background-color 0.3s ease; */
}
.homeMainFeatures .card:hover .card-body .card-title{
  color: #fff !important;
}
/* homeMainFeatures */
/* card start */
.card .card-body .icon{
  border: 1px solid;
  padding: 0.75rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  color: #0A6CC7 !important;
  margin-bottom: 10px;
}
.card-title{
  color: #0A6CC7 !important;
  font-size: 1rem !important;
  margin-bottom: 5px !important;
}
/* card end */
section{
  padding: 70px 0;
}

/* about us */
.aboutimg{
  width: 70%;
  display: block;
  margin: 0 auto;
}
.aboutimg img{
  width: 100%;
  border-radius: 30px;
}
.about{
  position: absolute;
  top: 50px;
  right:0;
  width: 185px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 0.75rem;
}
.about h6, .services h6{
  color: #0A6CC7;
}
.about p, .services p{
  margin-bottom: 0;
}
.services{
  position: absolute;
  bottom: 50px;
  left:0;
  width: 130px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 0.75rem;
}
.services .icon{
position: absolute;
background-color: #0A6CC7;
color: #fff;
border-radius: 50%;
height: 30px;
width: 30px;
text-align: center;
align-items: center;
left: -25px;
top: 20px;
}
.services h6{
  margin-bottom: 0;
}
.card-text{
  font-size: 0.85rem;
}
/* innerHeader */
.innerHeader { 
  background-repeat:no-repeat;
  background-position: left;
}
.container-fluid {
  background: linear-gradient(180deg, #1137ac 1.92%, rgba(0, 0, 0, 0) 99.03%);
  padding: 130px 15px 70px 15px;
  mix-blend-mode: normal;
}
.innerHeader h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
}
.innerHeader h1, .innerHeader h4, .innerHeader p{
  color: #fff;
}
.innerHeader h4{
  text-transform: uppercase;
  font-weight: 500 !important;
}
/* Business Types Start*/
.businessCard {
  align-items: center;
}
.businessCard .icon {
  border: 1px solid;
  padding: 0.75rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  color: #0A6CC7 !important;
}
.businessCard .card-title {
  color: #000000 !important;
  font-size: 1rem !important;
}
.businessIndustries a {
  text-decoration: none;
}
.businessTypeImage .imgCard{
  width: 100%;
  display: block;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 140px;
  overflow: hidden;
  align-items: center;
  margin-bottom: 10px;
}
.businessTypeImage .imgCard:last-child{
  margin-bottom: 0;
}
.businessTypeImage img{
  width: 100%;
  display: block;
}
/* Business Types End*/
.productfeatures .card .card-body .icon {
  border: 1px solid;
  padding: 0.75rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: 56px;
  height: 56px;
  color: #0A6CC7 !important;
  margin: 0 auto;
  margin-bottom: 10px;
  line-height: 0;
  font-size: 2rem;
}
.card-text{
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  align-self: stretch;
  display: -webkit-box;
  overflow: hidden;
}
/*  */
footer{
  padding: 70px 0;
  background-color: #0A6CC7;
}
.footer-logo img{
  width: 90%;
  display: block;
}
.copyright{
  padding: 15px 0;
  background-color: #075CAB;
  text-align: center;
  color: #ffffff;
}
.copyright a{
  color: #ffffff;
}

footer ul{
  list-style-type: none;
  padding: 0;
}
footer ul li a{
  color: #ffffff;
  text-decoration: none;
}
.quickaction{
  background-color: #075CAB;
  padding: 30px 0;
}
/* Pricing */
.pricing{
  background-color: #0A6CC7 !important;
}
.pricing-card{
  border-radius: 30px !important;
}
.pricing-card:hover{
  border-color: #F9A947 !important;
  cursor: default;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.pricing-card h2 small{
  font-size: 26px;
  font-weight: 400 !important;
}
.pricing-card h5{
  font-weight: 500 !important;
}
.pricing-card h4{
  font-weight: 500 !important;
}
.pricing-card .pricingFeatures{
  background-color: #0A6CC7;
  padding: 15px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
}
.pricing-card:hover .pricingFeatures{
  background-color: #F9A947;
}
.pricing-card:hover .btn-primary{
  background-color: #F9A947;
  border-color: #F9A947;
}
/* testimonials-slide */

.testimonials-slide .card{
  color: #ffffff;
  margin-bottom: 0.5rem;
}
.testimonials-slide .card:nth-child(odd){
  background-color: #0A6CC7;
}
.testimonials-slide .card:nth-child(even){
  background-color: #F9A947;
}
.testimonials-slide .controls button{
  margin-right: 5px;
}
.testimonials-slide .controls button:last-child{
  margin-right: 0;
}
.author-section {
  display: flex;
  align-items: center;
}
.author-thumbnail {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border: 1px solid #ffffff;
}

.author-section p {
  margin-bottom: 0;
  font-weight: bold;
}


header{
  background:#0A6CC7;
}
.overlay-container {
  position: relative;
  height: 100vh;
  text-align: center;
  color: #ffffff;
  background-size: cover !important;
  background-position: calc(10% -50%);
  mix-blend-mode: multiply;
  padding: 0 !important;
}

.overlay-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  

}

.content {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; /* Adjust the text color as needed */
}

/* Additional styling for the content */
.content h1 {
  font-size: 2em;
}



.business {
  background-size: cover !important;
  padding: 0;
}
.container-fluid {
  background: linear-gradient(180deg, #1137ac 1.92%, rgba(0, 0, 0, 0) 99.03%);
  padding: 130px 15px 70px 15px;
}


.slick-slide {
  padding:5px;
  text-align:center;
}
.form-control{
  font-size: 0.85rem !important;
}




.sectionData{
  position: relative;
}
.sectionData:nth-child(even) .container .row .info:nth-child(odd) {
  position: absolute;
  right: 0;
}


.two-column-list {
  columns: 2; /* Set the number of columns */
  /* list-style-type: none; */
  padding: 0;
}

.two-column-list li {
  margin-bottom: 0em;
}
/* contact-form-main */
.contact-form-main .row{
  align-items: center;
}
.contact-form-main ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.contact-form-main ul li{
  display: flex;
  margin-bottom: 15px;
}
.contact-form-main ul li .icon{
  margin-right: 10px;
  margin-top: 5px;
  color: #F9A947;
}

.modal-title{
  color: #0A6CC7 !important;
}

.modal-body .icon {
  border: 1px solid;
  padding: 1.75rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  color: #0A6CC7 !important;
  margin-bottom: 10px;
}
.modal-body .icon:hover {
  border-color:#F9A947;
  color: #F9A947 !important;
}
.nav-tabs {
  border: 0 !important;
}
.nav-tabs .nav-item .nav-link{
  font-size: 1.1rem;
  border: 0 !important;
  border-radius: 2rem;
  color: var(--bs-body-color);
  background-color: #ffffff;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:visited, .nav-tabs .nav-link:hover {
  isolation: isolate;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #F9A947 !important;
  
}
.whatsapp{
  background: url(./img/chat.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  object-fit: cover;
  animation: zoomInOut 2s infinite alternate;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.whatsapp a{
  height: 6rem;
  width: 6rem;
  text-indent:-9999px;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


/* .zoom-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoomInOut 5s infinite alternate;
} */